export const SOCIALS = [
  {
    key: 'vk',
    icon: {
      src: '/icons/social/vk.svg',
    },
    link: {
      href: '//vk.com/litres_samizdat',
      title: 'ВКонтакте — Литрес:Самиздат',
      target: '_blank',
    },
  },
  // TODO: пока в РФ сети фейсбук и инстаграм признаны экстремисткими, мы их закоментим, но не удалим, вдруг что-то изменится
  // {
  //   key: 'fb',
  //   icon: {
  //     src: '/icons/social/fb.svg',
  //   },
  //   link: {
  //     href: '//www.facebook.com/litressamizdat/',
  //     title: 'Facebook — Литрес:Самиздат',
  //     target: '_blank',
  //   },
  // },
  {
    key: 'yt',
    icon: {
      src: '/icons/social/yt.svg',
    },
    link: {
      href: '//www.youtube.com/channel/UCE_jbVm6UA5HmPftTklSMGg',
      title: 'Youtube — Литрес:Самиздат',
      target: '_blank',
    },
  },
  {
    key: 'telegram',
    icon: {
      src: '/icons/social/telegram.svg',
    },
    link: {
      href: '//t.me/LitResPublishing',
      title: 'Telegram — Литрес:Самиздат',
      target: '_blank',
    },
  },
  {
    key: 'dzen',
    icon: {
      src: '/icons/social/dzen.svg',
    },
    link: {
      href: '//dzen.ru/litressamizdat',
      title: 'Дзен — Литрес:Самиздат',
      target: '_blank',
    },
  },
  // {
  //   key: 'instagram',
  //   icon: {
  //     src: '/icons/social/instagram.svg',
  //   },
  //   link: {
  //     href: '//www.instagram.com/litres_samizdat/',
  //     title: 'Instagram — Литрес:Самиздат',
  //     target: '_blank',
  //   },
  // },
];
